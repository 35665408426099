import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Services from "../components/Services";
import Footer from "../components/Footer";
import "../styles/HomePage.css";

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <HeroSection />
      <Services />
      <Footer />
    </div>
  );
};

export default HomePage;
