import React, { useEffect, useState } from "react";
import { fetchServices } from "../services/api";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import "../styles/ServicesPage.css";

const ServicesPage = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const loadServices = async () => {
      const data = await fetchServices();
      setServices(data);
    };

    loadServices();
  }, []);

  return (
    <div className="services-page">
      <Header />
      <h1>Our Services</h1>
      <div className="services-list">
        {services.map((service) => (
          <div key={service.id} className="service-item">
            <img
              src={
                service._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                "https://via.placeholder.com/150"
              }
              alt={service.title.rendered}
            />
            <h2>{service.title.rendered}</h2>
            <p>{service.excerpt.rendered.replace(/<[^>]+>/g, "")}</p>
            <Link to={`/services/${service.id}`} className="view-more">
              View More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
