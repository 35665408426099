import React, { useEffect, useState } from "react";
import { fetchServices } from "../services/api";
import { Link } from "react-router-dom";
import "../styles/Services.css";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const loadServices = async () => {
      const data = await fetchServices();
      setServices(data);
    };

    loadServices();
  }, []);

  return (
    <section className="services-section">
      <h2>Our Services</h2>
      <div className="services-container">
        {services.map((service) => (
          <div key={service.id} className="service-box">
            <img
              src={
                service._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                "https://via.placeholder.com/150"
              }
              alt={service.title.rendered}
            />
            <h3>{service.title.rendered}</h3>
            <p>{service.excerpt.rendered.replace(/<[^>]+>/g, "")}</p>
            <Link to={`/services/${service.id}`} className="view-more-button">
              View More
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
