import React, { useEffect, useState } from "react";
import { fetchPageContent } from "../services/api";
import "../styles/HeroSection.css";

const HeroSection = () => {
  const [heroContent, setHeroContent] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    const loadContent = async () => {
      const data = await fetchPageContent(8); // Fetching content from page ID 8
      if (data) {
        setHeroContent({
          title: data.title.rendered,
          description: data.content.rendered,
        });
      }
    };

    loadContent();
  }, []);

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>{heroContent.title || "Loading..."}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html:
              heroContent.description ||
              "Please wait while we load the content.",
          }}
        />
        <button className="cta-button">Learn More</button>
      </div>
    </section>
  );
};

export default HeroSection;
