import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { fetchPageContent } from "../services/api";
import ContactForm from "../components/ContactForm";
import "../styles/ContactPage.css";

const ContactPage = () => {
  const [contactContent, setContactContent] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    const loadContent = async () => {
      const data = await fetchPageContent(20);
      if (data) {
        setContactContent({
          title: data.title.rendered,
          description: data.content.rendered,
        });
      }
    };

    loadContent();
  }, []);

  return (
    <div className="contact-page">
      <Header />
      <h1>{contactContent.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: contactContent.description }} />
      <ContactForm />
    </div>
  );
};

export default ContactPage;
