import React, { useEffect, useState } from "react";
import { fetchServiceContent } from "../services/api";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import "../styles/ServiceDetail.css";

const ServiceDetail = () => {
  const { id } = useParams();
  const [service, setService] = useState({
    title: "",
    content: "",
    featuredImage: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadService = async () => {
      setLoading(true);
      const data = await fetchServiceContent(id);
      if (data) {
        setService({
          title: data.title.rendered,
          content: data.content.rendered,
          featuredImage:
            data._embedded?.["wp:featuredmedia"]?.[0]?.source_url || "",
        });
      }
      setLoading(false);
    };

    loadService();
  }, [id]);

  if (loading) {
    return <div className="service-detail">Loading...</div>;
  }

  return (
    <div className="service-detail">
      <Header />
      {service.featuredImage && (
        <div className="service-image">
          <img src={service.featuredImage} alt={service.title} />
        </div>
      )}
      <h1>{service.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: service.content }} />
    </div>
  );
};

export default ServiceDetail;
