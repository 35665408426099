import React, { useEffect, useState } from "react";
import { fetchPageContent } from "../services/api";
import "../styles/Footer.css";

const Footer = () => {
  const [footerContent, setFooterContent] = useState("");

  useEffect(() => {
    const loadContent = async () => {
      const data = await fetchPageContent(22); // Replace with your actual Footer page ID
      if (data) {
        setFooterContent(data.content.rendered);
      }
    };

    loadContent();
  }, []);

  return (
    <footer className="footer">
      <div dangerouslySetInnerHTML={{ __html: footerContent }} />
    </footer>
  );
};

export default Footer;
