import axios from "axios";

const BASE_URL = "https://expertdevelopment.co.nz/wp-json/wp/v2";

// Function to fetch a single page by ID (for general pages)
export const fetchPageContent = async (pageId) => {
  try {
    const response = await axios.get(`${BASE_URL}/pages/${pageId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching page content for ID ${pageId}:`, error);
    return null;
  }
};

// Function to fetch a single service by ID (for services custom post type)
export const fetchServiceContent = async (serviceId) => {
  try {
    const response = await axios.get(`${BASE_URL}/service/${serviceId}?_embed`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching service content for ID ${serviceId}:`, error);
    return null;
  }
};

// Function to fetch all services (for list view)
export const fetchServices = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/service?_embed`);
    return response.data;
  } catch (error) {
    console.error("Error fetching services:", error);
    return [];
  }
};
